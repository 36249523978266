<template>
  <ion-page>
    <ion-header>
      <app-header back="home" title="비밀번호찾기" />
    </ion-header>
    <ion-content :fullscreen="true">
      <div id="container">
  
          <ion-item lines="none" class="my_label">
            <ion-text stacked color="danger">해당하는 정보를 입력하신 후 인증버튼을 클릭하시면 기본정보 인증 후 비밀번호가 초기화됩니다
            </ion-text>
          </ion-item>
            <ion-item>
            <ion-label position="floating">사용자ID</ion-label>
            <ion-input type="text" v-model="form.id" maxlength="10" placeholder="사용자의 아이디를 입력해주세요"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating">사업자번호</ion-label>
            <ion-input type="text" v-model="form.sano" maxlength="12" placeholder="사업자번호를 입력해주세요"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating">이메일</ion-label>
            <ion-input type="text" v-model="form.email" maxlength="50" placeholder="이메일을 입력주세요"></ion-input>
          </ion-item>
          <ion-item v-if="chk" lines ="none" text-center>
           <ion-text color="primary" class ="selectable" >초기화된 비밀번호는 {{form.password}}@#!! 입니다.<br/>대한시멘트 담당자의 승인 후 초기화된 비밀번호로 로그인 가능합니다. </ion-text> 
          </ion-item>
          <div class='center-button'>
            <ion-button class="record-btn" color="medium" @click="submit" :disabled= "chk == false ? false : true">인증</ion-button>
          </div>

      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { users } from '@/api/services/user';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonItem, IonLabel, IonInput, IonButton, modalController, IonPage, IonText, IonCard } from "@ionic/vue";
import { defineComponent, inject, reactive} from "vue";
import { Emitter, EventType } from 'mitt';
export default defineComponent({

  name: "Modal",
  components: { IonContent, IonHeader,  IonItem, IonLabel, IonInput, IonButton, IonPage, IonText },
  setup() {
    const { isConnected, updateUserInfo, fetchUserInfos, deafultPassword, chk } = users();
    const emitter = inject<Emitter<Record<EventType, unknown>>>('emitter');
    const form = reactive({
        id: '',
        sano: '',
        email: '',
        password: ''
    });
    
    function closeModal() {
      modalController.dismiss();
    }
    function submit() {

    deafultPassword(form.id,form.sano, form.email)
    form.password = form.id;
    }
    return {
      form,
      closeModal,
      updateUserInfo,
      submit,
      isConnected,
      fetchUserInfos,
      deafultPassword,
      chk
    }
  }
});

</script>

<style>
.content-wrap {
  padding: 0 0.5em;
}
.selectable {
  user-select: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
}
.center-button {
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 50px;
  height: 20%;
}

.my_label {
  padding-top: 1em;
}

.record-btn {
  height: 40px;
  width: 120px;
}
</style>